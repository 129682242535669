@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700&display=swap");
@import "~react-image-gallery/styles/css/image-gallery.css";
@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Kenyan";
  src: local("Kenyan"),
    url("./Assets/fonts/kenyan-regular.otf") format("opentype");
  font-weight: 400;
}
