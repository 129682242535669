@import url("https://fonts.googleapis.com/css2?family=Rubik+Iso&display=swap");

html,
body {
	padding: 0;
	margin: 0;
	font-family: "Open Sans", sans-serif;
	scroll-behavior: smooth;
	/* Overrides the border radius setting in the theme. */
	--ck-border-radius: 4px;

	/* Overrides the default font size in the theme. */
	--ck-font-size-base: 14px;

	/* Helper variables to avoid duplication in the colors. */
	--ck-custom-background: hsl(270, 1%, 29%);
	--ck-custom-foreground: hsl(255, 3%, 18%);
	--ck-custom-border: hsl(300, 1%, 22%);
	--ck-custom-white: hsl(0, 0%, 100%);

	/* -- Overrides generic colors. ------------------------------------------------------------- */

	--ck-color-base-foreground: var(--ck-custom-background);
	--ck-color-focus-border: hsl(208, 90%, 62%);
	--ck-color-text: hsl(0, 0%, 98%);
	--ck-color-shadow-drop: hsla(0, 0%, 0%, 0.2);
	--ck-color-shadow-inner: hsla(0, 0%, 0%, 0.1);

	/* -- Overrides the default .ck-button class colors. ---------------------------------------- */

	--ck-color-button-default-background: var(--ck-custom-background);
	--ck-color-button-default-hover-background: hsl(270, 1%, 22%);
	--ck-color-button-default-active-background: hsl(270, 2%, 20%);
	--ck-color-button-default-active-shadow: hsl(270, 2%, 23%);
	--ck-color-button-default-disabled-background: var(--ck-custom-background);

	--ck-color-button-on-background: var(--ck-custom-foreground);
	--ck-color-button-on-hover-background: hsl(255, 4%, 16%);
	--ck-color-button-on-active-background: hsl(255, 4%, 14%);
	--ck-color-button-on-active-shadow: hsl(240, 3%, 19%);
	--ck-color-button-on-disabled-background: var(--ck-custom-foreground);

	--ck-color-button-action-background: hsl(168, 76%, 42%);
	--ck-color-button-action-hover-background: hsl(168, 76%, 38%);
	--ck-color-button-action-active-background: hsl(168, 76%, 36%);
	--ck-color-button-action-active-shadow: hsl(168, 75%, 34%);
	--ck-color-button-action-disabled-background: hsl(168, 76%, 42%);
	--ck-color-button-action-text: var(--ck-custom-white);

	--ck-color-button-save: hsl(120, 100%, 46%);
	--ck-color-button-cancel: hsl(15, 100%, 56%);

	/* -- Overrides the default .ck-dropdown class colors. -------------------------------------- */

	--ck-color-dropdown-panel-background: var(--ck-custom-background);
	--ck-color-dropdown-panel-border: var(--ck-custom-foreground);

	/* -- Overrides the default .ck-splitbutton class colors. ----------------------------------- */

	--ck-color-split-button-hover-background: var(
		--ck-color-button-default-hover-background
	);
	--ck-color-split-button-hover-border: var(--ck-custom-foreground);

	/* -- Overrides the default .ck-input class colors. ----------------------------------------- */

	--ck-color-input-background: var(--ck-custom-background);
	--ck-color-input-border: hsl(257, 3%, 43%);
	--ck-color-input-text: hsl(0, 0%, 98%);
	--ck-color-input-disabled-background: hsl(255, 4%, 21%);
	--ck-color-input-disabled-border: hsl(250, 3%, 38%);
	--ck-color-input-disabled-text: hsl(0, 0%, 78%);

	/* -- Overrides the default .ck-labeled-field-view class colors. ---------------------------- */

	--ck-color-labeled-field-label-background: var(--ck-custom-background);

	/* -- Overrides the default .ck-list class colors. ------------------------------------------ */

	--ck-color-list-background: var(--ck-custom-background);
	--ck-color-list-button-hover-background: var(--ck-color-base-foreground);
	--ck-color-list-button-on-background: var(--ck-color-base-active);
	--ck-color-list-button-on-background-focus: var(
		--ck-color-base-active-focus
	);
	--ck-color-list-button-on-text: var(--ck-color-base-background);

	/* -- Overrides the default .ck-balloon-panel class colors. --------------------------------- */

	--ck-color-panel-background: var(--ck-custom-background);
	--ck-color-panel-border: var(--ck-custom-border);

	/* -- Overrides the default .ck-toolbar class colors. --------------------------------------- */

	--ck-color-toolbar-background: var(--ck-custom-background);
	--ck-color-toolbar-border: var(--ck-custom-border);

	/* -- Overrides the default .ck-tooltip class colors. --------------------------------------- */

	--ck-color-tooltip-background: hsl(252, 7%, 14%);
	--ck-color-tooltip-text: hsl(0, 0%, 93%);

	/* -- Overrides the default colors used by the ckeditor5-image package. --------------------- */

	--ck-color-image-caption-background: hsl(0, 0%, 97%);
	--ck-color-image-caption-text: hsl(0, 0%, 20%);

	/* -- Overrides the default colors used by the ckeditor5-widget package. -------------------- */

	--ck-color-widget-blurred-border: hsl(0, 0%, 87%);
	--ck-color-widget-hover-border: hsl(43, 100%, 68%);
	--ck-color-widget-editable-focus-background: var(--ck-custom-white);

	/* -- Overrides the default colors used by the ckeditor5-link package. ---------------------- */

	--ck-color-link-default: hsl(190, 100%, 75%);
}

.font-rubik {
	font-family: "Rubik Iso", cursive;
}

/* CalendarCSS */
.rangePicker .react-daterange-picker__wrapper {
	border: none;
}

.rangePicker
	.react-daterange-picker__wrapper
	.react-daterange-picker__inputGroup {
	color: #6a6a6a;
}

.react-daterange-picker__inputGroup input {
	outline: none !important;
}

.rangePicker
	.react-daterange-picker__wrapper
	.react-daterange-picker__range-divider {
	color: #6a6a6a;
}

.react-daterange-picker__clear-button {
	display: none;
}

.react-daterange-picker__button:enabled:focus
	.react-daterange-picker__button__icon {
	stroke: rgba(255, 255, 255, 0.5) !important;
}

.react-daterange-picker__calendar-button__icon {
	stroke: #6a6a6a;
}

.react-daterange-picker__calendar .react-calendar {
	background-color: #1e1e1e !important;

	box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1),
		0 4px 6px -4px rgb(0 0 0 / 0.1);
	border: 1px solid #6a6a6a;
	border-radius: 20px;
}

.react-calendar__navigation button:first-child {
	border-top-left-radius: 20px;
}

.react-calendar__navigation button:last-child {
	border-top-right-radius: 20px;
}

.react-calendar__navigation button {
	color: #6a6a6a;
	background-color: #1e1e1e !important;
}

.react-calendar__navigation button:enabled:focus {
	color: #6a6a6a;
	background-color: #1e1e1e !important;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
	background-color: #1e1e1e !important;
}

.react-calendar__month-view__weekdays__weekday {
	color: rgba(255, 255, 255, 0.6);
}

.react-calendar__month-view__days__day {
	color: rgba(255, 255, 255, 0.6);
}

.react-calendar__tile--hasActive,
.react-calendar__tile--now {
	border-radius: 20px;
	background: #76baff2b !important;
}

.react-calendar__tile--active,
.react-calendar__tile--hover {
	background: rgba(168, 85, 247, 0.3) !important;
}

.react-calendar__year-view__months__month {
	color: rgba(255, 255, 255, 0.8);
}

.react-calendar__decade-view .react-calendar__tile {
	color: rgba(255, 255, 255, 0.8);
}

/* Common css for modal */
.react-responsive-modal-modal {
	background: #111111 !important;
	border-radius: 10px !important;
	width: 90%;
}

.react-responsive-modal-closeButton {
	color: white !important;
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
	max-height: calc(70vh - 80px) !important;
}

.image-gallery-slide .image-gallery-description {
	width: max-content !important;
	margin: 0 auto !important;
	bottom: 0 !important;
	left: 0 !important;
	right: 0 !important;
}

#habit-tooltip {
	background-color: #696969;
	max-width: 250px;
}

.ck-editor__editable {
	min-height: 400px !important;
	padding: 20px !important;
}

@keyframes fade-in-slow {
	0% {
		opacity: 0;
		display: block;
	}

	50% {
		opacity: 0.5;
	}

	100% {
		position: fixed;
		inset: 0;
		opacity: 1;
		z-index: 20;
	}
}

@keyframes fade-out-slow {
	0% {
		position: fixed;
		inset: 0;
		opacity: 1;
		z-index: 20;
	}

	50% {
		position: fixed;
		inset: 0;
		opacity: 0.8;
		z-index: 20;
	}

	80% {
		position: fixed;
		inset: 0;
		opacity: 0.5;
		z-index: 20;
	}

	100% {
		position: fixed;
		opacity: 0;
		z-index: 20;
	}
}

.animate-fade-out-slow {
	animation-name: fade-out-slow;
	animation-duration: 0.5s;
	animation-timing-function: ease-in-out;
	animation-fill-mode: forwards;
}

.animate-fade-in-slow {
	animation-name: fade-in-slow;
	animation-duration: 0.5s;
	animation-timing-function: ease-in-out;
	animation-fill-mode: forwards;
}

.gradient-bg {
	background: linear-gradient(90deg, #ff4655, purple);
}

.text-wrap {
	white-space: pre-wrap;
	/* Standard property */
	white-space: -moz-pre-wrap;
	/* Firefox */
	white-space: -o-pre-wrap;
	/* Opera */
	white-space: -ms-pre-wrap;
	/* Internet Explorer */
	white-space: -webkit-pre-wrap;
	/* Safari */
}

.swiper-pagination-bullet {
	background: white !important;
}

@media only screen and (max-width: 700px) {
	.react-responsive-modal-container {
		height: unset !important;
	}
	.swiper-button-prev {
		top: 20px !important;
	}

	.swiper-button-prev::after {
		font-size: 20px !important;
	}

	.swiper-button-next {
		top: 20px !important;
	}

	.swiper-button-next::after {
		font-size: 20px !important;
	}

	.training-detail .swiper-pagination {
		top: 0 !important;
		bottom: unset !important;
		width: max-content !important;
		left: 42% !important;
	}

	.training-detail .swiper {
		min-height: 80vh !important;
		display: flex !important;
		align-items: center !important;
	}
}

.loading-bar {
	width: 100%;
	height: 2px;
	background-color: #ddd;
	position: relative;
	overflow: hidden;
	border-radius: 2px;
}

.loading-bar::after {
	content: "";
	display: block;
	background-color: purple;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	animation: loading 2s infinite;
}

@keyframes loading {
	0% {
		transform: translateX(-100%);
	}

	100% {
		transform: translateX(100%);
	}
}
.recharts-legend-wrapper {
	display: none;
}
